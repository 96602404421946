<template>
  <div class="edp-home">
    <edp-header ref="homeHeader"></edp-header>

    <section class="edp-home__title" ref="homeTitle">
      {{ $t("home.title") }}
    </section>

    <section
      class="edp-home__subTitle"
      v-html="$t('home.subTitle')"
      ref="homeSubTitle"
    ></section>

    <section class="edp-home__search" ref="homeSearch">
      <div class="edp-home__search__text">
        <input
          type="text"
          class=""
          :placeholder="$t('home.searchPlaceholder')"
          v-model="seacrhMember_text"
        />
      </div>

      <span class="edp-home__search__button" @click="seacrhMember">{{
        $t("home.searchButtonName")
      }}</span>
    </section>

    <section class="edp-home__tsContent">
      <span
        class="edp-home__swiper__control controlPrev"
        @click="home_slide('left')"
        v-show="activeIndex > 0"
        :style="'top:' + bsHeight"
      ></span>
      <span
        class="edp-home__swiper__control controlNext"
        @click="home_slide('right')"
        v-show="activeIndex < mySwiper_home_list.length - 1"
        :style="'top:' + bsHeight"
      ></span>
    </section>

    <section class="edp-home__bsContent" ref="homeBs">
      <section class="edp-home__swiper">
        <swiper
          :ref="'mySwiper_home'"
          @slideChangeTransitionEnd="onSlideChange"
        >
          <swiper-slide
            v-for="(item, index) in mySwiper_home_list"
            :key="'mySwiper_home' + index"
            class="edp-home__swiper__slide"
          >
            <div
              v-for="(item2, index2) in item"
              :key="'mySwiper_home_in' + index2"
              class="edp-home__swiper__slide__in"
              @click="linkTo(item2.modLinkUrl)"
              :style="
                'background-image:url(' +
                item2.modImgPrefix +
                '' +
                item2.modImgUrl +
                ')'
              "
            >
              <!--<img :src="item2.modImgPrefix + '' + item2.modImgUrl" alt="" />-->

              <div
                class="edp-home__swiper__slide__title"
                v-html="
                  $i18n.locale === 'cn'
                    ? item2.modCnName || item2.modEnName
                    : item2.modEnName || item2.modCnName
                "
              ></div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "home",
  data() {
    return {
      mySwiper_home_list: [],
      activeIndex: 0,
      seacrhMember_text: "",
      bsHeight: "0px",
    };
  },
  methods: {
    ...mapActions({
      userModule: "login/userModule",
    }),
    seacrhMember() {
      this.$router.push({
        path: "/memberList",
        query: { keyWord: this.seacrhMember_text, type: 0 },
      });
    },
    onSlideChange() {
      this.activeIndex = this.$refs["mySwiper_home"].swiper.activeIndex;
    },
    home_slide(type) {
      let numTo = this.$refs["mySwiper_home"].swiper.activeIndex,
        mySwiperLength = this.mySwiper_home_list.length;

      if (type == "left") {
        if (numTo > 0) {
          this.activeIndex = numTo - 1;
          this.$refs["mySwiper_home"].swiper.slideTo(
            this.activeIndex,
            1000,
            false
          );
        }
      } else {
        if (numTo < mySwiperLength - 1) {
          this.activeIndex = numTo + 1;
          this.$refs["mySwiper_home"].swiper.slideTo(
            this.activeIndex,
            1000,
            false
          );
        }
      }
    },
    linkTo(val) {
      this.$router.push("/" + val);
    },
    bsRezize() {
      if (
        this.$refs.homeHeader &&
        this.$refs.homeTitle &&
        this.$refs.homeSubTitle &&
        this.$refs.homeSearch
      ) {
        let allHeight =
          parseInt(this.$refs.homeHeader.blankHeight) +
          this.$refs.homeTitle.clientHeight +
          this.$refs.homeSubTitle.clientHeight +
          this.$refs.homeSearch.clientHeight;

        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;

        windowHeight = windowHeight > 600 ? windowHeight : 600;

        this.bsHeight = (windowHeight - allHeight) / 2 + "px";
        this.$refs.homeBs.style.height = windowHeight - allHeight + "px";
      }
    },
  },
  async mounted() {
    let res = await this.userModule({ current: 1, size: 1000 });

    let newSwiperArr = res.data.data || [];

    newSwiperArr.forEach((val, e) => {
      let arryNum = e % 6;

      if (arryNum == 0) {
        this.mySwiper_home_list.push([]);
      }
    });

    newSwiperArr.forEach((val, e) => {
      let arryNum = e / 6;

      this.mySwiper_home_list.forEach((val2, e2) => {
        if (arryNum < e2 + 1 && arryNum >= e2) {
          this.mySwiper_home_list[e2].push(val);
        }
      });
    });

    setTimeout(() => {
      this.bsRezize();

      window.addEventListener("resize", this.bsRezize);
    }, 100);
  },
  destroyed() {
    window.removeEventListener("resize", this.bsRezize);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-home {
  position: relative;
  width: 100%;
  background: linear-gradient(
    166.25deg,
    #ffffff 1.65%,
    rgba(250, 250, 250, 0) 91.83%
  );
}
.edp-home__title {
  font-family: JLREmeric;
  width: 100%;
  text-align: center;
  font-size: toPad(50);
  padding-top: toPad(30);
  font-weight: bold;
}

.edp-home__subTitle {
  font-family: JLREmeric;
  width: 100%;
  text-align: center;
  font-size: toPad(27);
  padding-top: toPad(5);

  .subStrong1 {
    font-weight: bold;
  }
  .subStrong2 {
    font-weight: bold;
    color: #3e886d;
  }
}

.edp-home__search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: toPad(25) 0 toPad(30) 0;

  .edp-home__search__text {
    display: flex;
    width: toPad(460);
    height: toPad(40);
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    background: rgba(217, 217, 217, 0.3);
    border-radius: toPad(3);
    overflow: hidden;

    input {
      width: 100%;
      padding: 0 toPad(18);
      border: 0;
      font-size: toPad(14);
      background: transparent;

      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .edp-home__search__button {
    display: inline-flex;
    align-items: center;
    height: toPad(40);
    flex-shrink: 0;
    margin-left: toPad(6);
    padding: 0 toPad(26);
    cursor: pointer;
    background: #3e886d;
    border-radius: toPad(3);
    color: #fff;
  }
}

.edp-home__bsContent {
  position: relative;
  width: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;

  min-height: toPad(320);
}
.edpPage_pc {
  .edp-home__bsContent {
    width: 75%;
  }
}

.edp-home__tsContent {
  position: relative;
  width: 75%;
  height: 0;
  z-index: 2;
  margin: 0 auto;
}

.edp-home__swiper {
  position: absolute;
  width: 100%;
  height: calc(100% - 30px);

  box-sizing: content-box;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .swiper-container {
    position: relative;
    height: 100%;
  }
  .swiper-wrapper {
    height: 100%;
  }
}
.edp-home__swiper__slide {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.edp-home__swiper__slide__in {
  position: relative;
  width: 33.33%;
  height: 50%;
  overflow: hidden;
  float: left;
  cursor: pointer;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .edp-home__swiper__slide__title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: toPad(24);
    padding-left: toPad(30);
    z-index: 1;
    font-size: toPad(26);
    color: #fff;
    font-family: JLREmeric;
    line-height: 1.2;
  }
}

.edp-home__swiper__control {
  position: absolute;
  z-index: 2;
  display: block;
  width: toPad(55);
  height: toPad(55);
  transform: translateY(-50%);
  border-radius: 100%;
  cursor: pointer;
  background: url("~@/src/assets/common/icon-left.png") no-repeat center center;
  background-size: auto toPad(20);
  background-color: #fff;
  border-radius: 100%;
  box-shadow: toPad(7) toPad(13) toPad(20) #ccc;
  margin-top: toPad(-15);

  &.controlPrev {
    left: toPad(-80);
  }
  &.controlNext {
    right: toPad(-80);
    background: url("~@/src/assets/common/icon-right.png") no-repeat center
      center;
    background-size: auto toPad(20);
    background-color: #fff;
  }
}

@media screen and (min-width: 750px) and (max-width: 1600px) {
  .edp-home__swiper__slide__in {
    .edp-home__swiper__slide__title {
      font-size: toPad(20);
    }
  }
}
@media screen and (min-width: 1600px) {
  .edp-home__title {
    padding-top: toPad(50);
  }
  .edp-home__search {
    padding: toPad(35) 0 toPad(35) 0;
  }
}
</style>
